import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "inicio",
    component: () => import(/* webpackChunkName: "inicio" */ '@/views/Inicio.vue'),
  },
  {
    path: "/nosotros",
    name: "nosotros",
    component: () => import(/* webpackChunkName: "nosotros" */ '@/views/Nosotros.vue'),
  },
  {
    path: "/productos",
    name: "productos",
    component: () => import(/* webpackChunkName: "productos" */ '@/views/Productos.vue'),
    children: [
      {
        path: 'quimicos',
        component: () => import(/* webpackChunkName: "quimicos" */ '@/views/Productos/Quimicos.vue'),
      },
      {
        path: 'dispensadores',
        component: () => import(/* webpackChunkName: "dispensadores" */ '@/views/Productos/Dispensadores.vue')
      },
      {
        path: 'escobas',
        component: () => import(/* webpackChunkName: "escobas" */ '@/views/Productos/Escobas.vue')
      },
      {
        path: 'trapeadores',
        component: () => import(/* webpackChunkName: "trapeadores" */ '@/views/Productos/Trapeadores.vue')
      },
      {
        path: 'absorbentes',
        component: () => import(/* webpackChunkName: "abosorbentes" */ '@/views/Productos/Absorbentes.vue')
      },
      {
        path: 'fundas-plasticas',
        component: () => import(/* webpackChunkName: "fundas-plasticas" */ '@/views/Productos/FundasPlasticas.vue')
      },
      {
        path: 'descartables',
        component: () => import(/* webpackChunkName: "descartables" */ '@/views/Productos/Descartables.vue')
      },
      {
        path: 'complementarios',
        component: () => import(/* webpackChunkName: "complementarios" */ '@/views/Productos/Complementarios.vue')
      }
    ]
  },
  {
    path: "/contacto",
    name: "contacto",
    component: () => import(/* webpackChunkName: "contacto" */ '@/views/Contacto.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'paginaNoEncontrada',
    component: () => import(/* webpackChunkName: "paginaNoEncontrada" */ '@/views/404.vue'),
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if(savedPosition) {
      return savedPosition
    } else if(to.matched[1]) {
      if(from.name == 'inicio' || from.name == 'nosotros') {
        return new Promise( resolve => {
          setTimeout( () => resolve({el:'.catalogo', behavior: 'smooth'}), 400)
        })
      }
      return {
        el: '.catalogo',
        behavior: 'smooth',
      }
    } else {
      return new Promise( resolve => {
        setTimeout( () => resolve({top:0}), 300)
      })
    }
  }
});

export default router;
